import { useEffect, useCallback, useState } from 'react';
import { get, patch, deleteData } from 'utils/axiosRequests';
import { globalAction, dispatch } from 'store';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useListing(url, secondaryURL) {
    const { total, currentPage, recordPerPage, searchStr, listingData, order, orderBy, currentData } = useSelector(
        (state) => state.listing
    );
    const [stateFilter, setStateFilter] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    // const location = useLocation();
    const query = new URLSearchParams(location.search);
    const queryPage = query.get('page');

    const resetListing = () => {
        dispatch(globalAction('RESET_LISTING'));
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: 1 }));
        dispatch(globalAction('SET_SEARCH_STR', { searchStr: '' }));
        dispatch(globalAction('SET_ORDER', { order: 'DESC', orderBy: 'id' }));
        dispatch(globalAction('CHANGE_RECORD_PER_PAGE', { recordPerPage: 10 }));
        dispatch(globalAction('SET_STATE_FILTER', { stateFilter: [] }));
    };

    const getListing = async () => {
        setIsLoading(true);
        const params = { page: queryPage, order, orderBy, limit: recordPerPage };
        if (searchStr) params.search = searchStr;
        if (stateFilter.length > 0) params.filter = JSON.stringify(stateFilter);

        try {
            const response = await get(url, params);
            setIsLoading(false);
            dispatch(
                globalAction('SET_LISTING', {
                    listingData: response.data,
                    total: response.total,
                    currentPage: queryPage || 1
                })
            );
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    };

    const setCurrentData = (currentData) => {
        dispatch(globalAction('SET_CURRENT_DATA', { currentData }));
    };

    // Reset all states and refetch data whenever the pathname changes
    useEffect(() => {
        resetListing();
        getListing();
    }, [location.pathname]);

    useEffect(() => {
        setCurrentData(undefined);
        getListing();
    }, [currentPage, searchStr, order, orderBy, url, recordPerPage, stateFilter]);

    // const setpage = (event, page) => {
    //     dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: page + 1 }));

    // };
    const setpage = (event, page) => {
        const params = new URLSearchParams({
            page
        });
        navigate({ search: params.toString() });
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: page }));
    };

    const setSearchStr = (str) => {
        dispatch(globalAction('SET_SEARCH_STR', { searchStr: str }));
    };

    const updateStatus = async (id, status) => {
        if (window.confirm(`Are you sure to ${status}`)) {
            patch(`${url}/${id}`, { status }).then((res) => {
                if (res.status === 'success') {
                    getListing();
                }
            });
        }
    };

    const changeStatus = (event, id) => {
        const status = event.target.checked ? 'Active' : 'Inactive';
        updateStatus(id, status);
    };

    const deleteRow = async (e, id) => {
        if (window.confirm(`Are you sure to Delete`)) {
            deleteData(`${!secondaryURL ? url : secondaryURL}`, id).then((res) => {
                if (res.status === 'success') {
                    getListing();
                }
            });
        }
    };
    useEffect(() => {
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: queryPage || 1 }));
        setSearchStr('');
        setStateFilter([]);
    }, [url]);

    const changeOrder = useCallback((columnName) => {
        dispatch(globalAction('CHANGE_ORDER', { orderBy: columnName }));
    }, []);

    const rowsPerPage = (row) => {
        dispatch(globalAction('CHANGE_RECORD_PER_PAGE', { recordPerPage: row.target.value }));
    };

    return {
        total,
        currentPage,
        listingData,
        searchStr,
        recordPerPage,
        order,
        orderBy,
        isLoading,
        stateFilter,
        setpage,
        setSearchStr,
        changeStatus,
        deleteRow,
        setCurrentData,
        changeOrder,
        resetListing,
        rowsPerPage,
        getListing,
        setStateFilter
    };
}
